define(['jquery'], function($) {
  
  //detect touch devices - change the slider parameters for touch enabled devices.

  var $sliderDrag = 'ontouchstart' in document.documentElement ? 'true' : 'false';
  var $fadeinLoadedSlide = false;
  var $sliderSet = $('.slider-set .royal-slider');

  $sliderSet.each(function() {
    $(this).royalSlider({
      sliderDrag: $sliderDrag,
      transitionType: 'move',
      arrowsNav: false,
      loop: true,
      autoHeight: true,
      keyboardNavEnabled: true,
      controlsInside: true,
      imageScaleMode: 'fill',
      slidesSpacing: 0,
      minSlideOffset: 0,
      arrowsNavAutoHide: true,
      autoScaleSlider: false,
      controlNavigation: 'bullets',
      thumbsFitInViewport: false,
      numImagesToPreload: 6,
      startSlideId: 0,
      globalCaption: false,
      addActiveClass: true,
      imageAlignCenter: true,
      fadeinLoadedSlide: $fadeinLoadedSlide,
      navigateByClick: true,
      sliderTouch: true,
      allowCSS3: false,
      autoPlay: {
        // autoplay options go gere
        enabled: true,
        pauseOnHover: true,
        delay: 10000
      }

    });
  });

  $sliderSet.find('.btn[data-href]').click(function() {
    var location = $(this).attr('data-href');
    window.location = location;
    return false;
  });

});
